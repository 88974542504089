
<div class="wrapper">
    <app-backbutton></app-backbutton>
    <h1 class="text-2xl mt-4">{{course?.Bezeichnung}}</h1>
    <div class="mt-4">
        {{course?.Start | date: 'EEE dd.MM.'}} • {{course?.Start | date: 'HH:mm'}} - {{course?.Ende | date: 'HH:mm'}} • {{course?.Minuten}} Minuten
    </div>
    <div>{{ course?.Ort }}</div>
    <mat-divider></mat-divider>
    <h2 class="text-xl mt-4">Anmeldung für</h2>
    <h3 class="text-lg">{{ login?.token?.vorname }}</h3>

    <button *ngIf="success === false" mat-flat-button color="primary" class="my-4" (click)="bookCourse()" >Verbindlich anmelden</button>
    <p *ngIf="success === true">
        Vielen Dank für Deine Anmeldung. Wir freuen uns auf deinen Besuch.
    </p>
    <mat-divider style="margin: 10px;"></mat-divider>
    <p>Bitte melde dich nur an, wenn du auch wirklich teilnehmen möchtest. Solltest du doch nicht teilnehmen können,
        kannst du dich bis 1 Stunde vor dem Kurs abmelden.</p>
</div>
