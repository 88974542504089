import { Component, OnInit, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { formatDate } from '@angular/common';
import { Observable, of, Subject } from 'rxjs';

import { NewsService } from '../_services/news.service';

import '../_model/slim';
import { AuthenticationService } from '../_services/auth.service';
import { User } from '../_model/user';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit, OnDestroy {

  news$: Observable<Slim>;
  user: User;
  destroy$ = new Subject();

  constructor(private newsService: NewsService,
              private authService: AuthenticationService) { }
  ngOnDestroy(): void {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.news$ = this.loadNews();
    this.authService.currentUser
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (data) => {
        this.user = data;
      },
      error: (err) => {
        console.log(err);
      }
    });
    //console.log(this.news$);
  }

  loadNews(): Observable<any> {
    return this.newsService.loadNews();


  }

}
