import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tncount'
})
export class TncountPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let result = 'Ausgebucht';
    if (value > 5) { result = 'noch mehr als 5 Plätze frei'; }
    if (value <= 5 && value > 1) { result = 'noch ' + value + ' Plätze frei'; }
    if (value === 1) { result = 'noch 1 Platz frei'; }
    return result;
  }

}
