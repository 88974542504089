<div class="container mx-auto">

    <h1 class="text-xl text-center p-4">Login</h1>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-col p-4" >
            <mat-form-field class="example-full-width"  appearance="outline">
                <mat-label>EDV-Nummer</mat-label>
                <input matInput type="text" placeholder="" formControlName="username">
            </mat-form-field>
            <mat-form-field class="example-full-width"  appearance="outline">
                <mat-label>Geburtstag</mat-label>
                <input matInput type="password" placeholder="01.01.1970" formControlName="password">
            </mat-form-field>
            <button mat-flat-button color="primary" type="submit"  [disabled]="!loginForm.valid">Login</button>
        </div>
    </form>
    <div *ngIf="error" class="warning">{{ error }}</div>
</div>
