
<div class="mat-typography wrapper">
    <app-backbutton></app-backbutton>
    <div class="imgbox mat-elevation-z6">
        <div class="verlauf">
            <img class="responsive" [src]="course?.Bildquelle" />
        </div>
        <h1 class="mat-h1 textbottom">{{course?.Bezeichnung}}</h1>
    </div>
    
    
    <div>
        <mat-icon class="vmiddle" aria-hidden="false" aria-label="clock icon">schedule</mat-icon>
        {{course?.Start | date: 'EEE dd.MM.'}} • {{course?.Start | date: 'HH:mm'}} - {{course?.Ende | date: 'HH:mm'}} • {{course?.Minuten}} Minuten
    </div>
    <div>
        <mat-icon class="vmiddle" aria-hidden="false" aria-label="place icon">place</mat-icon>
        {{ course?.Ort }}
    </div>

    <div class="infobox" *ngIf="course?.canceled == 1">
        Leider fällt dieser Kurstermin aus
    </div>

    <div *ngIf="course.VorlaufMin > 0 && course?.canceled == 0" style="margin-top: 10px; margin-bottom: 10px;">
        <div class="infobox" *ngIf="course.Anmeldungab > now">
            Die Anmeldung für diesen Kurs ist ab {{ course.Anmeldungab | date: 'dd.MM.yyy HH:mm' }} möglich
        </div>
    </div>
    <div class="infobox" *ngIf="course.Ende < now">
        Dieser Kurs liegt in der Vergangenheit
    </div>
    <button *ngIf = "course?.Anmeldung && course?.canceled == 0 && (course?.maxTN-course?.aktTN > 0)" mat-flat-button color="primary" 
        class="tnbutton" [routerLink]="['/book', course?.TerminID]" routerLinkActive="router-link-active" >
        Jetzt teilnehmen
    </button>
    <button *ngIf = "course?.Warteliste && showWL" mat-flat-button color="primary" 
        class="tnbutton" (click)="waitinglistaction()" >
        Auf die Warteliste
    </button>   
    <div class="beschreibung mat-body">
        {{ course?.Beschreibung }}
    </div>
    <div class="data" fxLayout="column" fxLayoutGap="10px">
        <div class="free"><mat-icon class="vmiddle" aria-hidden="false" aria-label="free icon">how_to_reg</mat-icon>{{ course?.maxTN - course?.aktTN | tncount }}</div>
        <div class="level"><mat-icon class="vmiddle" aria-hidden="false" aria-label="level icon">star_outline</mat-icon>{{ course?.Level }}</div>
        <div class="trainer"><mat-icon class="vmiddle" aria-hidden="false" aria-label="trainer icon">person_outline</mat-icon>{{ course?.Trainer }}</div>
    </div>
</div>
