<div class="wrapper mat-typography">
    <div>
        <h2>Änderung deiner Daten</h2>
        <p>Hier kannst du deine E-Mail Adresse eintragen bzw. ändern. Wir benachrichtigen dich dann über Kursänderungen.</p>
    </div>
    <form *ngIf="user" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
        <div class="flex flex-col">
            <mat-form-field>
                <mat-label>Meine E-Mail Adresse</mat-label>
                <input matInput type="text" formControlName="Email">
            </mat-form-field>


            <button mat-flat-button color="primary" type="submit" [disabled]="!profileForm.valid">
                Speichern
            </button>
            <div class="alert" *ngIf="msg !== ''">
                {{ msg }}
            </div>
        </div>
      </form>
</div>
