import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  searchtext = new UntypedFormControl('');
  customers = [];

  constructor(
    public dialogRef: MatDialogRef<SearchComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient) {}

  onNoClick(): void {
    this.dialogRef.close(0);
  }

  ngOnInit(): void {
  }

  searchAction(): void {
    console.log(this.searchtext.value);
    if (this.searchtext.value.length === 0) {
      return;
    }
    const params = new HttpParams()
                        .set('name', this.searchtext.value);

    this.http.get<Slim>(environment.apiUrl + 'users/find', { params } )
    .subscribe(res => {
      console.log(res);
      this.customers = res.data;
    },
    err =>  {
      console.log(err);
    });

  }

  closeDialog(customerid) {
    this.dialogRef.close(customerid);
  }

}
