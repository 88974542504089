import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injectable, isDevMode } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import * as Hammer from 'hammerjs';


import { AuthInterceptor } from './_services/auth.interceptor';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoursesComponent } from './courses/courses.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule  } from '@angular/forms';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatFormFieldModule  } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';

import { CustomDateAdapter } from './_helper/custom-date-adapter';
import { registerLocaleData } from '@angular/common';
import localeDE from '@angular/common/locales/de';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { LoginComponent } from './login/login.component';
import { CourseConfirmationComponent } from './course-confirmation/course-confirmation.component';
import { MyCoursesComponent } from './my-courses/my-courses.component';
import { CourseComponent } from './admin/course/course.component';
import { DialogComponent } from './_shared/dialog/dialog.component';
import { ConfirmationDialogComponent } from './_shared/confirmation-dialog/confirmation-dialog.component';
import { NewsComponent } from './news/news.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TncountPipe } from './_shared/tncount.pipe';
import { MyDataComponent } from './my-data/my-data.component';
import { AboutComponent } from './about/about.component';
import { BackbuttonComponent } from './_shared/backbutton/backbutton.component';
import { ImgFallbackDirective } from './_shared/img-fallback/img-fallback.directive';
import { SearchComponent } from './admin/search/search.component';
import { FreecolorPipe } from './_shared/freecolor.pipe';
import { NotificationService  } from './_services/notification.service';
import { QrCodeModule } from 'ng-qrcode';


registerLocaleData(localeDE);

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
      // override hammerjs default configuration
      'swipe': { direction:  Hammer.DIRECTION_HORIZONTAL },
      'pinch': { enable: false },
      'rotate': { enable: false },
      'pan': { enable: false }
  } as any;
}

@NgModule({
  declarations: [
    AppComponent,
    CoursesComponent,
    NavbarComponent,
    CourseDetailComponent,
    LoginComponent,
    CourseConfirmationComponent,
    MyCoursesComponent,
    CourseComponent,
    DialogComponent,
    ConfirmationDialogComponent,
    NewsComponent,
    TncountPipe,
    MyDataComponent,
    AboutComponent,
    BackbuttonComponent,
    ImgFallbackDirective,
    SearchComponent,
    FreecolorPipe
  ],
  imports: [
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatMenuModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    QrCodeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    // { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    NotificationService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
