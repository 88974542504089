import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'img[appImgFallback]'
})
export class ImgFallbackDirective {

  @Input() amppImgFallback: string;

  constructor(private eRef: ElementRef) { }

    @HostListener('error')
    OnError(): void {
      const element: HTMLImageElement = this.eRef.nativeElement;
      element.src = this.amppImgFallback || '/assets/img/newsdefault.jpg';
    }


}
