import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import '../_model/inotification';
import { environment } from '../../environments/environment';
import '../_model/slim';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _notifications = new BehaviorSubject<INotification[]>([]);
  // readonly notifications = this._notifications.asObservable();
  private notes: INotification[] = [];

  constructor(private http: HttpClient) {

    console.log('Construct Notification Service');
    // const note = {title: 'leer', message: 'test', confirmed: false };
    // this.notes.push(note);
    // const note1 = {title: 'leer2', message: 'test2', confirmed: false };
    // this.notes.push(note1);
    // this._notifications.next(Object.assign([], this.notes ));

    // console.log(this.notifications);
  }

  get notifications() {
    return this._notifications.asObservable();
  }




  getMail(): void {
    console.log('Updating notifications');
    this.notes = [];
    this.http.get<Slim>(environment.apiUrl + 'users')
    .subscribe(data => {
      if (data) {
        if (!data.data['Email'] || data.data['Email'].length < 5) {
          const note = {title: 'Keine E-Mail', message: 'Du hast keine E-Mail Adresse hinterlegt. Um Dich rechtzeitig über Änderungen zu benachrichtigen, wäre es schön, wenn du deine E-Mail Adresse hinterlegst', confirmed: false };
          this.notes.push(note);
          this._notifications.next(Object.assign([], this.notes ));
        }
        else {
          this._notifications.next(Object.assign([], this.notes ));
        }
      }
    },
    err => {
      console.log(err);
      this.clearAll();
    });

  }

  clearAll(): void {
    this.notes = [];
    this._notifications.next(Object.assign([], this.notes ));
  }


}
