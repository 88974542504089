import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { formatDate } from '@angular/common';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  constructor(private http: HttpClient,
              @Inject(LOCALE_ID) private locale: string) { }

  loadCoursesofWeek(monday: Date): any {
    const params = new HttpParams()
      .set('day', formatDate(monday, 'yyyy-MM-dd', this.locale));

    return this.http.get<Slim>(environment.apiUrl + 'courses',  { params });
  }

  loadCourseId(id: number): any {
    return this.http.get<Slim>(environment.apiUrl + 'courses/' + id);
  }

  postCourse(terminid: number): any {
    return this.http.post<Slim>(environment.apiUrl + 'bookings/' + terminid, {});
  }

  getCoursesOfUser(): any {
    return this.http.get<Slim>(environment.apiUrl + 'users/courses');
  }

  deleteBooking(id: number): any {
    return this.http.delete<Slim>(environment.apiUrl + 'bookings/' + id, {});
  }

  addToWaitinglist(id: number): any {
    return this.http.post<Slim>(environment.apiUrl + 'waitinglist/' + id, {});
  }
}
