import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../_services/auth.service';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { SnackbarService } from '../../_services/snackbar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import '../../_model/slim';
import { SearchComponent } from '../search/search.component';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {

  course: any = {};
  terminid = 0;
  teilnehmer = [];
  fullname = '';
  currentUser: any = {};
  tnloading = false;

  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              private authService: AuthenticationService,
              private snackBar: SnackbarService,
              private dialog: MatDialog) {
                this.authService.currentUser
                .subscribe(data => {
                  this.currentUser = data;
                });
              }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      this.terminid = +params['id'];
      this.loadCourse(this.terminid);
      this.loadTeilnehmer();
    });
  }

  loadCourse(id: number): void {
    this.http.get<Slim>(environment.apiUrl + 'courses/' + id)
    .subscribe(res => {
      console.log(res);
      this.course = res.data;
    },
    err =>  {
      console.log(err);
    });
  }

  addTeilnehmer(): void {
    if (this.fullname.length === 0) {
      return;
    }
    const data = { userid: -1, name: this.fullname };
    console.log(data);
    this.http.post<Slim>(environment.apiUrl + 'bookings/' + this.terminid, data)
    .subscribe(res => {
      console.log(res);
      this.fullname = '';
      this.snackBar.openSnackBar('Teilnehmer hinzugefügt');
      this.loadTeilnehmer();
    },
    err => {
      this.snackBar.openSnackBar(err.error.data.msg);
      console.log(err);
    } );
  }

  cancelCourse(id: number): void {
    this.http.delete<Slim>(environment.apiUrl + 'bookings/' + id, {})
    .subscribe(res => {
      console.log(res);
      this.snackBar.openSnackBar('Teilnehmer entfernt');
      this.loadTeilnehmer();
    },
    err => {
      console.log(err);
      this.snackBar.openSnackBar(err.error.data);
    } );
  }

  loadTeilnehmer(): void {
    this.tnloading = true;
    this.http.get<Slim>(environment.apiUrl + 'bookings/' + this.terminid)
    .subscribe(res => {
      console.log(res);
      this.teilnehmer = res.data;
      this.tnloading = false;
    },
    err =>  {
      this.tnloading = false;
      console.log(err);
      this.snackBar.openSnackBar('Fehler beim Laden der Teilnehmer');
    });
  }

  toggleStatus(bookingid: number) {
    this.http.put<Slim>(environment.apiUrl + 'bookings/' + bookingid + '/state', {})
    .subscribe(res => {
      console.log(res);
      this.snackBar.openSnackBar('Teilnehmerstatus geändert');
      this.loadTeilnehmer();
    },
    err => {
      console.log(err);
    } );
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(SearchComponent, {
      width: '500px',
      height: '500px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed with id ' + result);

      if (result && result !== 0) {
        this.bookCourse(result);
      }
    });
  }

  bookCourse(userid): void {
    const data = { userid, guest: true };
    this.http.post<Slim>(environment.apiUrl + 'bookings/' + this.terminid, data)
    .subscribe(res => {
      console.log(res);
      // this.dialogService.openDialog('Anmeldung erfolgreich', 'Sie sind jetzt für diesen Kurs angemeldet', 'info');
      this.snackBar.openSnackBar('Anmeldung erfolgreich');
      this.loadTeilnehmer();
    },
    err => {
      console.log(err);
      this.snackBar.openSnackBar('Fehler: ' + err.error.data.msg);
    } );
  }



}
