<div id="coursesweek" style="position: relative;">
    <mat-spinner *ngIf="loading" diameter="40" style="position: absolute; top: 0; right: 0;"></mat-spinner>
    <div class="flex flex-col">


        <div class="weekpicker" fxLayoutAlign="center center">
            <div class="flex box justify-evenly">
                <button mat-button color="primary" (click)="addWeeks(-1)">
                    <mat-icon aria-hidden="false" aria-label="back icon">arrow_back_ios</mat-icon>
                </button>
                <div class="weektext">{{weektext}}</div>
                <button mat-button color="primary" (click)="addWeeks(+1)">
                    <mat-icon aria-hidden="false" aria-label="forward icon">arrow_forward_ios</mat-icon>
                </button>

            </div>

        </div>



        <div class="hidden md:flex">
            <div fxFlex="noshrink" class="weekday mat-typography" *ngFor="let weekday of courses" >
                {{weekday.datum}}
                <button *ngFor="let course of weekday.courses" [class.blurred]="course?.enabled == false"
                    [routerLink]="['/course', course.TerminId]" class="course-card mat-elevation-z2"
                    [ngStyle]="{'background-color': course.Farbe}">
                    <div style="position: relative;" [class.canceled]="course?.canceled == 1">
                        <div class="time">{{course.Start | date: 'HH:mm'}} • {{course.Minuten}} Minuten</div>
                        <div class="title">{{course.Bezeichnung}}</div>
                        <div class="level">{{course.Level}}</div>
                        <div class="free">
                            {{ course.maxTN - course.aktTN | tncount }}
                        </div>
                        <div class="bar" [ngClass]="course.maxTN - course.aktTN | freecolor"></div>
                        <button mat-icon-button *ngIf="currentUser?.role == 'admin'" style="position: absolute; right: 0; top: 0px; z-index:99" [routerLink]="['/','admin','course',course.TerminId]"><mat-icon>people</mat-icon></button>
                    </div>
                </button>
                <p *ngIf="weekday.courses.length == 0">Keine Kurse an diesem Tag</p>
            </div>
        </div>


        <div class="flex md:hidden">
            <mat-accordion multi="false" style="width: 100%;">
                <mat-expansion-panel *ngFor="let weekday of courses" >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="datum">{{weekday.datumxs}}</span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <button *ngFor="let course of weekday.courses" [class.blurred]="course?.enabled == false"
                        [routerLink]="['/course', course.TerminId]" class="course-card mat-elevation-z2"
                        [ngStyle]="{'background-color': course.Farbe}">
                        <div style="position: relative;" [class.canceled]="course?.canceled == 1">
                            <div class="time">{{course.Start | date: 'HH:mm'}} • {{course.Minuten}} Minuten</div>
                            <div class="title">{{course.Bezeichnung}}</div>
                            <div class="level">{{course.Level}}</div>
                            <div class="free">
                                {{ course.maxTN - course.aktTN | tncount }}
                            </div>
                            <div class="bar" [ngClass]="course.maxTN - course.aktTN | freecolor"></div>
                            <button mat-icon-button *ngIf="currentUser?.role == 'admin'" style="position: absolute; right: 0; top: 0px; z-index:99" [routerLink]="['/','admin','course',course.TerminId]"><mat-icon>people</mat-icon></button>
                        </div>
                    </button>
                    <p *ngIf="weekday.courses.length == 0">Keine Kurse an diesem Tag</p>
                </mat-expansion-panel>
            </mat-accordion>
        </div>


    </div>
</div>

