import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '../_services/snackbar.service';
import { NotificationService } from '../_services/notification.service';

import '../_model/slim';

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.css']
})
export class MyDataComponent implements OnInit {

  user: any = {};
  msg = '';

  profileForm = new FormGroup({
    Email: new FormControl('', [Validators.required])
  });

  constructor(private http: HttpClient,
              private snackbar: SnackbarService,
              private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    this.http.get<Slim>(environment.apiUrl + 'users')
    .subscribe(data => {
      this.user = data.data;
      this.profileForm.patchValue(this.user);
    },
    err => {
      console.log(err);
      this.snackbar.openSnackBar('Fehler' + err.error.data);
    });
  }

  onSubmit() {
    this.msg = '';
    // TODO: Use EventEmitter with form value
    console.warn(this.profileForm.value);
    this.http.put<Slim>(environment.apiUrl + 'users/' + this.user.ID, this.profileForm.value)
    .subscribe(res => {
      console.log(res);
      this.snackbar.openSnackBar('Die E-Mail Adresse wurde geändert!');
      this.notificationService.getMail();
      // this.msg = 'Die E-Mail Adresse wurde geändert!';
    },
    err => {
      console.log(err);
      this.msg = 'Leider ist ein Fehler aufgetreten!';
    });
  }

}
