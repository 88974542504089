import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Inject, LOCALE_ID, OnDestroy, Pipe } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { SnackbarService } from '../_services/snackbar.service';
import { formatDate } from '@angular/common';
import { Observable, Subject } from 'rxjs';

import '../_model/slim';
import { CourseService } from '../_services/course.service';

@Component({
  selector: 'app-my-courses',
  templateUrl: './my-courses.component.html',
  styleUrls: ['./my-courses.component.css']
})
export class MyCoursesComponent implements OnInit, OnDestroy {

  user: any = {};
  user$: Observable<any>;
  destroy$ = new Subject();

  constructor(private courseService: CourseService,
              private snackBar: SnackbarService) { }
  ngOnDestroy(): void {
    this.destroy$.next(void 0);
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.loadCourses();
  }

  loadCourses(): void {
    this.user$ = this.courseService.getCoursesOfUser();
  }

  cancelCourse(id: number): void {
    this.courseService.deleteBooking(id)
    .pipe(takeUntil(this.destroy$))
    .subcribe({
      next: (res) => {
        this.snackBar.openSnackBar('Sie wurden vom Kurs abgemeldet');
        this.loadCourses();
      },
      error: (err) => {
        console.log(err);
        this.snackBar.openSnackBar(err.error.data);
      }
    });
  }

}
