import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from './_services/auth.service';
import { NotificationService } from './_services/notification.service';

import './_model/inotification';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'kursbuchung-fe';
  notifications$: Observable<INotification[]>;
  notecnt = 0;
  currentUser: any = {};

  constructor(private authService: AuthenticationService,
              private notificationService: NotificationService,
              private router: Router) {

    this.authService.currentUser
    .subscribe(data => {
      this.currentUser = data;
    });

    this.notifications$ = this.notificationService.notifications;

    this.notificationService.notifications.subscribe(data => {
      this.notecnt = data.length;
    });

    this.notificationService.getMail();

  }

  Logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
