<div class="flex flex-col">
    <div *ngIf="user?.card as card; else nocard">
      <h1 class="text-xl text-center">Mobile Checkin</h1>
      <qr-code class="flex justify-center" [value]="card" size="300" errorCorrectionLevel="M"></qr-code>
    </div>
    <ng-template #nocard>
      <p class="text-center">Keine Karte hinterlegt</p>
    </ng-template>
    <div *ngIf="(news$ | async)?.data as data; else loading" class="mat-typography p-4">
        <mat-card class="newscard" *ngFor="let itm of data">
            <div class="imgbox mb-4">
                <img mat-card-image [src]="itm.Bildquelle" appImgFallback />
                <mat-card-title class="mat-h1 textbottom p-1">{{ itm.Titel }}</mat-card-title>
            </div>
            <mat-card-content [innerHTML]="itm.Text"></mat-card-content>
        </mat-card>
    </div>
    <ng-template #loading>
        <div>Loading ...</div>
    </ng-template>
</div>
