import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoursesComponent } from './courses/courses.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { LoginComponent } from './login/login.component';
import { CourseConfirmationComponent } from './course-confirmation/course-confirmation.component';
import { MyCoursesComponent } from './my-courses/my-courses.component';
import { CourseComponent } from './admin/course/course.component';
import { NewsComponent } from './news/news.component';
import { MyDataComponent } from './my-data/my-data.component';
import { AboutComponent } from './about/about.component';


const routes: Routes = [
  { path: 'courses', component: CoursesComponent },
  { path: 'course/:id', component: CourseDetailComponent },
  { path: 'book/:id', component: CourseConfirmationComponent },
  { path: 'user/mycourses', component: MyCoursesComponent },
  { path: 'user/mydata', component: MyDataComponent },
  { path: 'admin/course/:id', component: CourseComponent },
  { path: 'login', component: LoginComponent },
  { path: 'news', component: NewsComponent },
  { path: 'about', component: AboutComponent },
  { path: '**', component: NewsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
