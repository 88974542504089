import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../_services/auth.service';
import { DialogService } from '../_services/dialog.service';
import { SnackbarService } from '../_services/snackbar.service';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';

import '../_model/slim';
import { CourseService } from '../_services/course.service';

@Component({
  selector: 'app-course-confirmation',
  templateUrl: './course-confirmation.component.html',
  styleUrls: ['./course-confirmation.component.css']
})
export class CourseConfirmationComponent implements OnInit {

  course: any;
  terminid = 0;

  login: any = {};
  currentUser: any = {};
  success = false;

  constructor(private courseService: CourseService,
              @Inject(LOCALE_ID) private locale: string,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthenticationService,
              private dialogService: DialogService,
              private snackbar: SnackbarService) {

                this.authService.currentUser
                .subscribe(data => {
                  this.currentUser = data;
                });
               }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      this.terminid = +params['id'];
      this.loggedIn();
      this.loadCourse(this.terminid);
    });
  }

  loggedIn(): void {
    this.authService.loggedIn()
      .subscribe(res => {
        console.log(res);
        this.login = res;
      },
        err => {
          console.log(err);
          if (err['status'] === 401) {
            this.router.navigate(['/login']);
          }
        });
    // this.http.get<Slim>(environment.apiUrl + 'user')
    // .subscribe(res => {
    //   console.log(res);
    //   this.login = res;
    // },
    // err =>  {
    //   console.log(err);
    //   if (err['status'] === 401) {
    //     this.router.navigate(['/login']);
    //   }
    // });
  }

  loadCourse(id: number): void {
    this.courseService.loadCourseId(id)
    .subscribe(res => {
      console.log(res);
      this.course = res.data;
    },
    err =>  {
      console.log(err);
      this.dialogService.openDialog('Fehler', err.error, 'error');
    });
  }

  bookCourse(): void {
    this.courseService.postCourse(this.terminid)
    .subscribe(res => {
      console.log(res);
      // this.dialogService.openDialog('Anmeldung erfolgreich', 'Sie sind jetzt für diesen Kurs angemeldet', 'info');
      this.snackbar.openSnackBar('Anmeldung erfolgreich');
      this.success = true;
    },
    err => {
      console.log(err);
      this.dialogService.openDialog('Fehler', err.error.data.msg, 'error');
    } );
  }
}

