import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { formatDate } from '@angular/common';
import { AuthenticationService } from '../_services/auth.service';
import { CourseService } from '../_services/course.service';

import '../_model/slim';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css'],
})
export class CoursesComponent implements OnInit {

  currentUser: any;
  courses: any;
  monday: Date;
  selectedDate: Date;
  weektext = '';
  dow = 1;
  loading = false;
  selected = '';
  weekdays = [  {id: 1, name: 'Montag', datum: '', datumxs: '', did: '', courses: [] },
                {id: 2, name: 'Dienstag', datum: '', datumxs: '', did: '', courses: [] },
                {id: 3, name: 'Mittwoch', datum: '', datumxs: '', did: '', courses: [] },
                {id: 4, name: 'Donnerstag', datum: '', datumxs: '', did: '', courses: [] },
                {id: 5, name: 'Freitag', datum: '', datumxs: '', did: '', courses: [] },
                {id: 6, name: 'Samstag', datum: '', datumxs: '', did: '', courses: [] },
                {id: 7, name: 'Sonntag', datum: '', datumxs: '', did: '', courses: [] }
  ];


  constructor(private http: HttpClient,
              private courseService: CourseService,
              private authService: AuthenticationService,
              @Inject(LOCALE_ID) private locale: string) {
                this.authService.currentUser
                .subscribe(data => {
                  this.currentUser = data;
                  // console.log('currentUser', data);
                });
               }

  ngOnInit() {
    console.log('Courses Component');
    let lastdate = new Date(localStorage.getItem('lastdate'));
    if (lastdate < new Date()) {
      lastdate = new Date();
    }
    this.selected = formatDate((new Date()), 'yyMMdd', this.locale);
    this.monday = this.getMonday(lastdate);
    console.log(this.monday);
    this.loadCourses();
  }

  getMonday(d: Date) {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  // changeDate(evt: any) {
  //   this.monday = this.getMonday(evt.value);
  //   this.loadCourses();
  // }

  addWeeks(weeks: number): void {
    console.log(this.monday);
    this.monday.setDate(this.monday.getDate() + (weeks * 7));
    console.log(weeks);
    this.loadCourses();
  }



  loadCourses(): void {
    localStorage.setItem('lastdate', formatDate(this.monday, 'yyyy-MM-dd', this.locale));
    this.loading = true;

    let sunday = new Date();
    this.dow = sunday.getDay();

    sunday = new Date(this.monday);
    sunday.setDate(sunday.getDate() + 6);
    this.weektext = formatDate(this.monday, 'dd.MM.yyyy', this.locale) + ' - ' + formatDate(sunday, 'dd.MM.yyyy', this.locale);
    // this.http.get<Slim>(environment.apiUrl + 'courses',  { params })
    this.courseService.loadCoursesofWeek(this.monday)
    .subscribe(res => {
      console.log(res);
      const courses = res.data;

      let weekdays = [];
      weekdays = this.weekdays;
      const curday = new Date(this.monday.valueOf());
      // Clear courses in weekdays
      weekdays.forEach(el => {
        el.courses = [];
        el.datum = formatDate(curday, 'EEE dd.MM.', this.locale);
        el.datumxs = formatDate(curday, 'EEEE dd.MM.', this.locale);
        el.did = formatDate(curday, 'yyMMdd', this.locale);
        curday.setDate(curday.getDate() + 1);
      });
      courses.forEach(element => {
        // console.log(element);
        const itm = weekdays.find(o => o.id === +element.Wota);
        const start = new Date(element.Start);
        element.enabled = false;
        const now = new Date();
        if (start > now || (this.currentUser && this.currentUser.role === 'admin')) {
          element.enabled = true;
        }
        // console.log(element);
        itm.courses.push(element);
        // console.log(itm);

      });
      // Group by Weekdays
      // this.courses = groupBy(courses, 'Wota');
      this.courses = weekdays;
      // console.log(this.monday);
      this.loading = false;
    },
    err => {
      console.log(err);
      this.loading = false;
    });
  }

}

const groupBy = (array, key) => {
  // Return the end result
  return array.reduce((result, currentValue) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, []); // empty object is the initial value for result object
};
