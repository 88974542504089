import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../_services/auth.service';
import { first } from 'rxjs/operators';
// import { DialogService } from '../_services/dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', Validators.required),
    password: new UntypedFormControl('', Validators.required)
  });

  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  tenantid: number;
  tenant: any;




  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private router: Router,
              private route: ActivatedRoute,
              // private dialog: DialogService
              ) { }

  ngOnInit(): void {
            // get return url from route parameters or default to '/'
            // tslint:disable-next-line:no-string-literal


            this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

            this.authService.logout();

  }

  onSubmit(): void {
    this.error = '';
    this.authService.login(this.loginForm.value.username, this.loginForm.value.password)
    .pipe(first())
    .subscribe(
        data => {
            this.router.navigate([this.returnUrl]);
        },
        error => {
            console.log(error);
            this.error = error.error.data;
            this.loading = false;
            // this.dialog.openDialog('Fehler', error.message, 'warning');
        });
  }



}
