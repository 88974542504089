import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  loadNews(): Observable<any> {
    return this.http.get<Slim>(environment.apiUrl + 'news').pipe(
      catchError((err) => {
        console.log(err);
        return of();
      })
    );
  }
}
