import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '../_shared/dialog/dialog.component';
import { ConfirmationDialogComponent } from '../_shared/confirmation-dialog/confirmation-dialog.component';
import { Dialog } from '../_model/dialog';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  confirmed = new Subject<boolean>();

  constructor(public dialog: MatDialog) { }

  openDialog(title, message, type): void {
      const data: Dialog = { title, message, type };
      const dialogRef = this.dialog.open(DialogComponent, {
          width: '300px',
          data
      });

      dialogRef.afterClosed().subscribe(result => {
          // console.log('The dialog was closed');
      });
  }

  openConfirmationDialog(question, yes, no): void {
    const data = { message: question,
      buttonText: {
        ok: yes,
        cancel: no
      }
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      // console.log('The dialog was closed');
      this.confirmed.next(result);
  });
  }
}
