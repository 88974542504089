<div class="container">
  <mat-toolbar>
    <button mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
    <img class="logoimage" src="./assets/img/logo.svg">
    <div fxLayout="row" style="width: 100%" fxLayoutAlign="start stretch">
      <a mat-button [routerLink]="['/','news']">News</a>
      <a mat-button [routerLink]="['/','courses']">Kursplan</a>
      <div style="flex-grow: 1"></div>
      <a *ngIf="currentUser" mat-button [routerLink]="['/','user','mycourses']">Meine Kurse</a>
      <button mat-button [matMenuTriggerFor]="menu1" *ngIf="currentUser" >
        <span class="material-icons">
        account_circle
        </span>
      </button>
      <mat-menu #menu1="matMenu">
        <button mat-menu-item [routerLink]="['/','user','mydata']">Meine E-Mail</button>
        <button mat-menu-item (click)="Logout()">Logout</button>
      </mat-menu>
      <button mat-button *ngIf="!currentUser" [routerLink]="['/','login']">Login</button>
    </div>
</mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #snav>
      <mat-nav-list>
        <a mat-list-item [routerLink]="['/']" routerLinkActive="router-link-active">Home</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>


