import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../environments/environment';
import { formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CourseService } from '../_services/course.service';


import '../_model/slim';
import { SnackbarService } from '../_services/snackbar.service';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.css']
})
export class CourseDetailComponent implements OnInit {

  course: any = {};
  terminid = 0;
  now = new Date();
  showWL = true;

  constructor(private courseService: CourseService,
              @Inject(LOCALE_ID) private locale: string,
              private route: ActivatedRoute,
              private snackbar: SnackbarService
              ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {

      this.terminid = +params['id'];
      this.loadCourse(this.terminid);
    });
  }

  loadCourse(id: number): void {
    this.courseService.loadCourseId(id)
    .subscribe(res => {
      console.log(res);
      this.course = res.data;
      this.course.Anmeldung = false;
      this.course.Anmeldungab = new Date(this.course.Anmeldungab);
      this.course.Start = new Date(this.course.Start);
      this.course.Ende = new Date(this.course.Ende);
      if (this.course.Anmeldungab < new Date() && this.course.Ende > new Date()) {
        this.course.Anmeldung = true;
      }
      console.log(this.course);
    },
    err =>  {
      console.log(err);
    });
  }

  waitinglistaction(): void {
    this.courseService.addToWaitinglist(this.terminid)
    .subscribe(res => {
      console.log(res);
      this.showWL = false;
      this.snackbar.openSnackBar(res.msg);
    },
    err => {
      console.log(err);
      this.snackbar.openSnackBar(err.error.msg);

    });
  }



}
