<div class="wrapper p-4">
    <h1 class="text-xl">{{ course.Bezeichnung }}</h1>
    <h3 class="text-lg">{{ course.Start | date: 'EEE dd.MM. HH:mm' }} Uhr</h3>
    <div class="teilnehmer" *ngIf="currentUser.role == 'admin'">

        <h2 class="text-lg mt-4">Teilnehmer</h2>
        <div class="flex flex-row">
            <mat-form-field appearance="fill">
                <mat-label>Manuell eintragen</mat-label>
                <input matInput [(ngModel)]="fullname" autocomplete="off">
            </mat-form-field>
            <button mat-icon-button (click)="addTeilnehmer()"><mat-icon>person_add</mat-icon></button>
            <div class="grow"></div>
            <button mat-icon-button (click)="openDialog()"><mat-icon>person_search</mat-icon></button>
        </div>
        <div style="font-weight: bold;">Maximal {{ course.maxTN }} Teilnehmer</div>


        <table style="width: 100%">
            <tr>
                <th>Nr.</th>
                <th>Name</th>
                <th>Buchungsdatum</th>
                <th>Status</th>
                <th>Aktion</th>
            </tr>
            <tr *ngFor="let tn of teilnehmer; let i = index">

                <td>{{ i + 1 }}.</td>
                <td>{{ tn.Teilnehmer }}</td>
                <td>{{ tn.Buchungsdatum | date: 'dd.MM.yyyy' }}</td>
                <td><button mat-icon-button (click)="toggleStatus(tn.BookingID)">
                    <mat-icon *ngIf="tn.Status == 0">verified</mat-icon>
                    <mat-icon *ngIf="tn.Status == 1">person_off</mat-icon>
                </button></td>
                <td><button mat-button (click)="cancelCourse(tn.BookingID)">Austragen</button></td>


            </tr>
        </table>
        <mat-spinner *ngIf="tnloading" diameter="40" ></mat-spinner>
    </div>
</div>
