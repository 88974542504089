import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { User } from '../_model/user';
import { NotificationService } from './notification.service';

import '../_model/slim';

import jwt_decode from 'jwt-decode';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient,
                private notificationService: NotificationService,
                private router: Router) {

        const token = JSON.parse(localStorage.getItem('scftoken'));
        // console.log(token);
        this.currentUserSubject = new BehaviorSubject<User>(token);
        this.currentUser = this.currentUserSubject.asObservable();
        if (this.isTokenExpired()) {
            console.log('Logout caused by token expiration');
            this.logout();
        }

    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }


    login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}token`, { username, password })
            .pipe(map(user => {
                console.log(user);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('scftoken', JSON.stringify(user.data));
                const expiresAt = this.getTokenExpirationDate(user.data.token);
                localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()) );
                this.currentUserSubject.next(user.data);
                this.notificationService.getMail();
                return user;
            }),
            catchError(err => {
                return throwError(err);
            }));
    }


    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('scftoken');
        localStorage.removeItem('expires_at');
        this.currentUserSubject.next(null);
        this.notificationService.clearAll();
    }

    getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);
        if (decoded.exp === undefined) {
            return null;
        }

        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }

    isTokenExpired(): boolean {
        const token = JSON.parse(localStorage.getItem('expires_at'));
        return !(token > new Date().valueOf());
    }

    loggedIn(): any {
        return this.http.get<Slim>(environment.apiUrl + 'user');
    }
}
