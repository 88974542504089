<div class="container mx-auto">

  <mat-toolbar class="navbar mat-elevation-z6">
    <a [routerLink]="['/']">
      <img class="logoimage" src="./assets/img/logored.svg">
    </a>
    <div class="flex" style="width: 100%">
      <div class="flex hidden sm:flex items-center" >
        <div class="w-40"></div>
        <a mat-button [routerLink]="['/','news']">News</a>
        <a mat-button [routerLink]="['/','courses']">Kursplan</a>
        <a *ngIf="currentUser" mat-button [routerLink]="['/','user','mycourses']">Meine Kurse</a>
      </div>
      <div class="grow"></div>
      <div class="flex">
        <div *ngIf="currentUser">
          <button mat-icon-button [matMenuTriggerFor]="notemenu">
            <mat-icon *ngIf="notecnt > 0; else noBadge" [matBadge]="notecnt" matBadgeColor="warn">
              notifications
            </mat-icon>
            <ng-template #noBadge>
              <mat-icon>
                notifications
              </mat-icon>
            </ng-template>
          </button>
          <mat-menu #notemenu="matMenu">
            <div *ngFor="let item of notifications$ | async">
              <div class="notification">{{ item.message }}</div>
            </div>
            <div *ngIf="notecnt == 0" class="notification">Keine Benachrichtigungen</div>
          </mat-menu>
          <button mat-icon-button [matMenuTriggerFor]="menu1">
            <mat-icon>
              account_circle
            </mat-icon>
          </button>
          <mat-menu #menu1="matMenu">
            <button mat-menu-item [routerLink]="['/','user','mycourses']">Meine Kurse</button>
            <button mat-menu-item [routerLink]="['/','user','mydata']">Meine E-Mail</button>
            <button mat-menu-item (click)="Logout()">Logout</button>
          </mat-menu>
        </div>
        <button mat-button *ngIf="!currentUser" [routerLink]="['/','login']">Login</button>
      </div>






    </div>
  </mat-toolbar>
  <div class="main">
    <router-outlet></router-outlet>
  </div>

  <div class="footer flex md:hidden justify-evenly">
    <button mat-button class="mat-button-normal text-white" [routerLink]="['/','news']">
      <div class="textbtn">
        <mat-icon>home</mat-icon>
        Start
      </div>
    </button>
    <button mat-button class="mat-button-normal" [routerLink]="['/','courses']">
      <div class="textbtn">
        <mat-icon>event</mat-icon>
        Kurse
      </div>
    </button>
    <button mat-button class="mat-button-normal" [routerLink]="['/','user','mycourses']">
      <div class="textbtn">
        <mat-icon>event_available</mat-icon>
        Meine Kurse
      </div>
    </button>
  </div>


</div>
