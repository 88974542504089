import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'freecolor'
})
export class FreecolorPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    let result = 'tngreen';
    if (value <= 5 && value > 0 ) { result = 'tnorange'; }
    if (value <= 0 ) { result = 'tnred'; }
    return result;
  }

}
