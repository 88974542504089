<div class="wrapper">
    <h1 class="text-2xl my-4">Meine Kurse</h1>
    <div *ngIf="(user$|async)?.data as user">
      <h2 class="text-xl my-2"><mat-icon style="vertical-align: middle; margin-right: 10px;">face</mat-icon>{{ user.Vorname }}</h2>
      <div>
          <div  *ngFor="let course of user?.courses" >
              <div class="box flex flex-col sm:flex-row justify-between items-center" >
                  <span class="kurs">{{ course?.Bezeichnung }}</span>
                  <span>{{ course?.Start | date: 'dd.MM.yy HH:mm' }} Uhr</span>
                  <button mat-mini-fab color="primary" (click)="cancelCourse(course?.BookingID)" ><mat-icon>delete_forever</mat-icon></button>
              </div>
          </div>
      </div>
      <div *ngIf="user?.courses?.length === 0">Keine Kurse reserviert</div>
    </div>
</div>
