import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../_services/auth.service';
import { map, catchError } from 'rxjs/operators';
import { Router, RouterState } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                ) {
                    console.log('Auth Interceptor');
                 }

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        const currentUser = this.authenticationService.currentUserValue;
        // console.log('withToken', currentUser);
        if (currentUser) {
            // console.log('withToken', currentUser);
            const cloned = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + currentUser.token)
            });

            // return next.handle(cloned);
            req = cloned;
        }


        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event--->>>', event);
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                data = {
                    reason: error && error.error && error.error.reason ? error.error.reason : '',
                    status: error.status
                };
                if (error.status === 401) {
                    const state: RouterState = this.router.routerState;
                    const snapshot = state.snapshot;
                    this.router.navigate(['/', 'login'], { queryParams: { returnUrl: snapshot.url } });
                }
                return throwError(error);
            })
        );



    }


}
