import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { AuthenticationService } from '../_services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  currentUser: any = {};

  constructor(private authService: AuthenticationService,
              private router: Router) {
    this.authService.currentUser
    .subscribe(data => {
      this.currentUser = data;
    });
  }

  Logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

}
