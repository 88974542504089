<div class="p-4">
    <h1 class="text-xl mb-3">Suche</h1>

        <form name="inputForm" (ngSubmit)="searchAction()">
            <div class="flex flex-row">
                <mat-form-field>
                    <mat-label>Suchbegriff</mat-label>
                    <input matInput type="text" [formControl]="searchtext" placeholder="Suchbegriff" autocomplete="off">
                </mat-form-field>

                <button mat-button type="submit" (click)="searchAction()">Suchen</button>
            </div>
        </form>


</div>
<div class="p-4">
    <ul>
        <li *ngFor="let customer of customers">
            <button mat-button (click)="closeDialog(customer.ID)">{{ customer.Suchname }}</button>
        </li>
    </ul>
</div>
